import useFeature from "@hooks/action/useFeature";
import Navigation from "./pages/Navigation";
import AppWrapper from "./scripts/AppWrapper";
import { useEffect } from "react";
import Prompt from "@components/molecules/Prompt";
import OfflineHandler from "@components/wrapper/Offline";
import { GoogleMapsProvider } from "@components/molecules/GoogleMapsProvider";
import { useRedStore } from "@store/store";
import { BLOCKED_USERS } from "@config/constant";
import { getMDMUser } from "api/service/user";
// import { initializeCleverTap } from "lib/clevertap";
// import clevertap from "clevertap-web-sdk";

// import { requestPermission } from "scripts/firebase/token";
// import { onMessage } from "firebase/messaging";
// import toast from "react-hot-toast";
// import { messaging } from "scripts/firebase/firebase";

// import CleverTap from "clevertap-web-sdk";

// const CLEVERTAP_ACCOUNT_ID = "46R-K65-9Z7Z";
// const CLEVERTAP_REGION = "in1";

// CleverTap.privacy.push({ optOut: false }); // Set to true if the user opts out of sharing their data
// CleverTap.privacy.push({ useIP: false }); // Set to true if the user agrees to share their IP data

// CleverTap.init(CLEVERTAP_ACCOUNT_ID, CLEVERTAP_REGION);

// Register the service worker
// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/clevertap_sw.js")
//       .then(registration => {
//         console.log("Service Worker registered with scope:", registration.scope);
//       })
//       .catch(error => {
//         console.error("Service Worker registration failed:", error);
//       });
//   });
// }

// Request permission for notifications using native Notification API
// if (Notification.permission === "default") {
//   Notification.requestPermission().then(function (permission) {
//     if (permission === "granted") {
//       // Show notification
//       clevertap.notifications.push({
//         titleText: "Permission granted",
//         bodyText: "You will now receive notifications.",
//         okButtonText: "OK",
//         rejectButtonText: "Cancel",
//       });
//     } else {
//       console.log("Notification permission denied");
//     }
//   });
// } else if (Notification.permission === "granted") {
//   // Show notification if permission is already granted
//   clevertap.notifications.push({
//     titleText: "Permission already granted",
//     bodyText: "You will now receive notifications.",
//     okButtonText: "OK",
//     rejectButtonText: "Cancel",
//   });
// } else {
//   console.log("Notification permission denied");
// }

export default function App() {
  const { initFeature } = useFeature();
  const user = useRedStore(state => state.user);

  useEffect(() => {
    initFeature();
    // requestPermission();
  }, [initFeature]);

  // onMessage(messaging, payload => {
  //   toast(<Message notification={payload.notification} />);
  // });

  useEffect(() => {
    const interval = setInterval(() => {
      const nextAutoLogoutAt :number = parseInt(localStorage.getItem("nextAutoLogoutAt") || '0') || new Date().setHours(24, 0, 0, 0);
      if(nextAutoLogoutAt < Date.now()){
        localStorage.setItem("nextAutoLogoutAt", `${new Date().setHours(24, 0, 0, 0)}`);
        window.location.pathname = "/auth/logout";
      }
    }, 60000);
  return () => clearInterval(interval);
  },[])

  useEffect(() => {
    if(user?.email && BLOCKED_USERS.includes(user?.email))
      window.location.pathname = "/auth/logout";
    if(user?.status == "inactive")
      window.location.pathname = "/auth/logout";
  },[user])

  const checkUserStatus = async (email: string | undefined) => {
    if (!email) return;
  
    try {
      const response :any = await getMDMUser(email);
      const userStatus = response?.data?.data?.[0]?.status;
  
      if (userStatus === "inactive") {
        window.location.pathname = "/auth/logout";
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  };
  
  useEffect(() => {
    if (!user?.email) return;
  
    const interval = setInterval(() => {
      checkUserStatus(user.email);
    }, 300000);
  
    return () => clearInterval(interval);
  }, [user?.email]);

  return (
    <OfflineHandler>
      <AppWrapper>
        <GoogleMapsProvider>
          <Prompt />
          <Navigation />
        </GoogleMapsProvider>
      </AppWrapper>
    </OfflineHandler>
  );
}

// const Message = ({ notification }: any) => {
//   return (
//     <>
//       <div id="notificationHeader">
//         {/* image is optional */}
//         {notification.image && (
//           <div id="imageContainer">
//             <img src={notification.image} width={100} />
//           </div>
//         )}
//         <span>{notification.title}</span>
//       </div>
//       <div id="notificationBody">{notification.body}</div>
//     </>
//   );
// };
